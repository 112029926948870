import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layouts";
import Hero from "../components/common/hero";
import Logo from "../components/common/logo";
import PrintList from "../components/print";
import RegenerativePledge from "../components/common/regenerativePledge";
import BreadCrumb from "../components/common/breadcrumb";
import Instagram from "../components/common/Instagram";
import Seo from "../components/seo";

export default function Print({
  data: { sanityPrintPage, sanitySitesettings },
}) {
  let seo;
  let hero;
  let breadcrumb;
  let regenerative;
  let printList;
  sanityPrintPage?.pageBuilder?.forEach((element) => {
    if (element._type === "seo") {
      seo = element;
    } else if (element._type === "heroSection") {
      hero = element;
    } else if (element._type === "breadcrumb") {
      breadcrumb = element;
    } else if (element._type === "regenerative") {
      regenerative = element;
    } else if (element._type === "printList") {
      printList = element;
    }
  });

  return (
    <Layout>
      <Seo data={seo} />
      <Hero heroSection={hero} h2Size="text-3xl sm:text-[60px]" h2P="pb-7" />
      <Logo logo={sanitySitesettings?.logo} />
      {printList && (
        <div className="tb-container">
          <PrintList data={printList} />
        </div>
      )}
      {breadcrumb && <BreadCrumb path={breadcrumb} />}
      <Instagram />
      {regenerative && (
        <div>
          <RegenerativePledge
            data={regenerative}
            min_h="min-h-[72vh]"
            pb="pb-[80px]"
            px="px-[10px]"
          />
          <Logo logo={sanitySitesettings?.logo} />
        </div>
      )}
    </Layout>
  );
}

export const query = graphql`
  query {
    sanityPrintPage {
      pageBuilder {
        ... on SanityHeroSection {
          _type
          heading1
          heading2
          ctaButton {
            link
            title
            type
            variant
            formId
          }
          bgImages {
            alt
            caption
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
            }
          }
        }
        ... on SanitySeo {
          _type
          description
          keywords
          ldSchema
          pagehandle
          title
        }
        ... on SanityPrintList {
          _type
          list {
            link
            _rawContent
            image {
              alt
              asset {
                gatsbyImageData(
                  formats: WEBP
                  placeholder: BLURRED
                  height: 150
                  width: 120
                )
              }
            }
          }
        }
        ... on SanityBreadcrumb {
          _type
          breadLink {
            breadPath
            nameLink
          }
          sectionBg {
            asset {
              url
            }
          }
        }
        ... on SanityRegenerative {
          _type
          heading
          _rawContent
          _rawAuthor
          sectionBg {
            asset {
              url
            }
          }
        }
      }
    }
    sanitySitesettings {
      logo {
        alt
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
        }
      }
    }
  }
`;
