import React from "react";
import { PortableText } from "@portabletext/react";
import { GatsbyImage } from "gatsby-plugin-image";

export default function PrintList({ data }) {
  return (
    <div className="max-w-[85%] mx-auto px-[10px] py-5">
      <div className="flex flex-wrap" id="promise-list">
        {data?.list?.map((item, index) => {
          return (
            <div
              className="flex w-full flex-col sm:flex-row justify-between show mb-2"
              key={index}
            >
              <div className="flex w-full justify-center relative">
                <GatsbyImage
                  image={item.image?.asset?.gatsbyImageData}
                  alt={item.image?.alt}
                />
              </div>
              <div className="w-full mb-[35px]">
                <a href={item?.link} target="_blank">
                  <div className="editor mt-2 sm:mt-0 text-center sm:text-left">
                    <PortableText
                      value={item._rawContent}
                      className="text-base text-assent font-semibold leading-[26px] max-w-[466px]"
                    />
                  </div>
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
